import api from '@/api'

const jobService = {
    create: async (file, jobType, options, use_job_input) => {
        const payload = {
            filename: file.name,
            content_type: file.type,
            job_type: jobType,
            options,
            use_job_input
        }
        return await api.post('jobs', payload)
        .then(res => res)
        .catch(err => { throw err })
    },
    get: async (jobId) => {
        return await api.get(`jobs/${jobId}`)
    },
    isComplete: (job) => {
        return ['FAILED', 'COMPLETE'].includes(job.job_status)
    },
    widgetToSczParams: (playbackDuration, transitionDuration) => {
        return {
            playback_duration_seconds: playbackDuration,
            transition_duration_ms: transitionDuration
        }
    },
    outputsExist: job => job.outputs ? job.outputs.length : false,
    outputByType: (job, outputType) => job.outputs.find(x => x.output_type === outputType),
    validOutput: (job, outputType) => jobService.outputsExist(job) && jobService.outputByType(job, outputType)
}

export default jobService