<template>
    <v-app>
        <v-app-bar app class="dropzone-depth-fix" color="primary" dark dense justify="space-between">
            <span :class="$vuetify.breakpoint.xsOnly ? '' : 'title2'">
                Media Optimisation Service
            </span>
            <v-spacer></v-spacer>
            <!-- <span v-if="$vuetify.breakpoint.smAndUp" class="mr-1">Provided by</span> -->
            <span class="mt-3">                    
                <a href="http://www.techmedia.com.au" class="white--text" target="_blank">
                    <img src="./assets/Techmedia_Logo_Neg_Colour-TL.png" height="24" />                        
                </a>
            </span>
        </v-app-bar>
        <v-main>
            <v-container>

                <v-row class="pt-4" justify="center" align="baseline">
                    <v-col>
                        <v-autocomplete
                            v-model="jobParams.jobType"
                            class="dropzone-depth-fix"
                            :items="jobTypes"
                            item-text="label"
                            return-object
                            label="Select job type"
                            :disabled="jobInstances.length > 0"
                            hide-details
                        />
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            color="primary"
                            depressed
                            :disabled="jobInstances.length == 0"
                            @click="reset()"
                        >
                            Reset
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row v-if="jobParams.jobType && jobParams.jobType.name == 'PACKAGE_WGT_AS_SCZ'">
                    <v-col cols="6" sm="4" md="3" lg="2">
                        <v-text-field
                            label="Playback Duration (seconds)"
                            v-model="jobParams.playbackDuration"
                            hide-details
                            type="number"
                            suffix="sec"
                        />
                        <v-btn
                            v-for="preset in playbackPresets" :key="preset"
                            class="caption2 text-lowercase mr-1"
                            height="16"
                            x-small
                            depressed
                            :disabled="jobParams.playbackDuration == preset"
                            @click="jobParams.playbackDuration = preset"
                        >
                            <span style="font-size: 11px">{{ preset }}</span>
                        </v-btn>
                    </v-col>
                    <v-col cols="6" sm="4" md="3" lg="2">
                        <v-text-field
                            label="Transition Duration (milliseconds)"
                            v-model="jobParams.transitionDuration"
                            hide-details
                            type="number"
                            suffix="ms"
                        />
                        <v-btn
                            v-for="preset in transitionPresets" :key="preset"
                            class="caption2 text-lowercase mr-1" 
                            height="16"
                            x-small
                            depressed
                            :disabled="jobParams.transitionDuration == preset"
                            @click="jobParams.transitionDuration = preset"
                        >
                            <span style="font-size: 11px">{{ preset }}</span>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row v-if="jobParams.jobType && jobParams.jobType.name == 'WILL_IT_PLAY'">
                    <v-col class="text-center mt-2 mb-n7">Scala Video Compatibility Tester</v-col>
                </v-row>

                <v-row v-if="jobParams.jobType && jobParams.jobType.name == 'OPTIMIZE_MEDIA'">
                    <v-col class="text-center mt-2 mb-n7">Scala Video Optimisation Tool</v-col>
                </v-row>

                <v-row justify="center" align="center" v-if="jobParams.jobType">
                    <v-col>
                        <dropzone @job-created="onJobCreated" :jobParams="jobParams"></dropzone>
                    </v-col>
                </v-row>

                <v-row justify="center" align="center" v-if="jobInstances.length">
                    <v-col>
                        <job-results-table 
                            :job-type-name="jobParams.jobType.name"
                            :jobs="jobInstances"
                            @job-updated="onJobUpdated"
                        />
                    </v-col>
                </v-row>
                
                <v-row v-if="jobParams.jobType && jobParams.jobType.name == 'WILL_IT_PLAY'" no-gutters>
                    <v-col class="caption2">
                        <v-divider></v-divider>
                        <div class="font-weight-bold pt-4 pb-1">Scala is compatible with the following video codecs:</div>
                        <ul>
                            <li>H.264 (MPEG-4 Part 10/AVC)</li>
                            <li>WMV (Windows Media Video)</li>
                            <li>MPEG1</li>
                            <li>MPEG2</li>
                        </ul>
                        <div class="font-weight-bold pt-2 pb-1">And the following audio codecs:</div>
                        <ul>
                            <li>MP3 (MPEG Audio Layer 3)</li>
                            <li>WMA (Windows Media Audio)</li>
                            <li>AAC (Advanced Audio Coding)</li>
                            <li>Any standard WAV codec (PCM, ADPCM)</li>
                        </ul>
                    </v-col>
                </v-row>

                <v-row v-if="jobParams.jobType && jobParams.jobType.name == 'OPTIMIZE_MEDIA'" no-gutters>
                    <v-col class="caption2">
                        <v-divider></v-divider>
                        <div class="font-weight-bold pt-4 pb-1">Scala Video Optimisation Tool:</div>
                        <div>
                            This tool will convert videos that aren't compatible with Scala to a preferred format. 
                            It will also accept already-compatible files and optimise them for Scala networks. 
                            The result will make efficient use of bandwidth whilst maintaining a high quality of playback.
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
        <v-footer>
            <v-row dense class="caption tertiary-text--text" :justify="$vuetify.breakpoint.xsOnly ? 'center' : 'space-between'">
                <v-col cols="auto">
                    <small>Media Optimisation Service v.{{ version }}</small>
                </v-col>
                <v-col cols="auto">
                    <small>Copyright &copy; 2024, TechMedia Digital Systems Pty Ltd</small>
                    <!-- <small class="ml-2"><a href="https://www.techmedia.com.au/tmeula" target="_blank">EULA</a></small> -->
                </v-col>
            </v-row>
        </v-footer>
    </v-app>
</template>

<script>
import jobService from "@/services/jobs";
import { getQueryParam, setQueryParam, deleteQueryParam } from "@/services/util";

export default {
    name: "App",
    components: {
        // BriscLogo: () => import("@/components/layout/BriscLogo"),
        Dropzone: () => import("@/components/Dropzone"),
        JobResultsTable: () => import("@/components/JobResultsTable")
    },    
    mounted() {
        const defaultJobType = this.getDefaultJobType();
        if (defaultJobType) {
            this.jobParams.jobType = defaultJobType
        }
        const defaultPlaybackDuration = getQueryParam('playbackDuration');
        if (defaultPlaybackDuration) {
            this.jobParams.playbackDuration = defaultPlaybackDuration
        }
        const defaultTransitionDuration = getQueryParam('transitionDuration');
        if (defaultTransitionDuration) {
            this.jobParams.transitionDuration = defaultTransitionDuration
        }
    },
    data: () => ({
        jobTypes: [
            {
                name: "EXTRACT_WGT_MEDIA",
                label: "Extract Widget Media",
                disabled: false
            },
            {
                name: "PACKAGE_WGT_AS_SCZ",
                label: "Package WGT as ScalaScript",
                disabled: false
            },
            {
                name: "WILL_IT_PLAY",
                label: "Will It Play?",
                disabled: false
            },
            {
                name: "OPTIMIZE_MEDIA",
                label: "Optimise Videos",
                disabled: false
            }
        ],
        jobInstances: [],
        jobParams: {
            jobType: null,
            playbackDuration: 10,
            transitionDuration: 250
        },
        playbackPresets: [8, 10, 15, 20],
        transitionPresets: [100, 250, 500],
        version: require('../package.json').version
    }),
    methods: {
        reset() {
            this.jobParams.jobType = null;
            this.jobInstances = [];
        },
        async onJobCreated (jobId) {
            const job = await jobService.get(jobId);
            this.jobInstances.push(job);
        },
        onJobUpdated (event) {
            this.$set(this.jobInstances, event.index, event.value) // table sorting must be disabled
        },
        getDefaultJobType () {
            const defaultJobType = getQueryParam('jobType');
            if (defaultJobType) {
                return this.jobTypes.find(
                    (element) => element.name == getQueryParam('jobType')
                );
            }
        }
    },
    watch: {
        'jobParams.jobType' (to) {
            if ( to ) setQueryParam('jobType', to.name)
            else deleteQueryParam('jobType')
        },
        'jobParams.playbackDuration' (to) {
            if ( to ) setQueryParam('playbackDuration', to)
        },
        'jobParams.transitionDuration' (to) {
            if ( to ) setQueryParam('transitionDuration', to)
        }
    }
};
</script>

<style>
.dropzone-depth-fix {
    z-index: 1001 !important;
}
@font-face {
    font-family: 'Poppins Regular';
    src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins SemiBold';
    src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
.v-main, .v-toolbar__content, .v-list-item__content {
    font-family: "Poppins Regular";
}
.title2 {
    font-family: "Poppins SemiBold";
    font-size: 1.25rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em !important;
}
.caption2 {
    font-size: 0.75rem !important;
    font-weight: 400;
    letter-spacing: 0.0333333333em !important;
    line-height: 1.25rem;
    font-family: "Poppins Regular" !important;
}
</style>